.TicTacToe {
  background-color: #61dafb;
  border: none;
  outline: none;
  margin: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 20px 20px;
  /* height: 100%; */
  aspect-ratio: 1 / 1;

  /* aspect-ratio: 1 / 1; */
}
