.Space {
  width: 80px;
  height: 80px;
  background-color: #282c34;
  color: #61dafb;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 80px;

  cursor: pointer;
}
